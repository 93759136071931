/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-globals */
import React from 'react'
import { getTranslate } from 'react-localize-alias'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './Root.module.scss'

import WeterIcon from '@assets/images/weter.inline.svg'
import GorodIcon from '@assets/images/gorod.inline.svg'
import VodaIcon from '@assets/images/voda.inline.svg'

import { Card, Project } from '@components/enter'
import { LanguageSelect } from '@components/App'

export const Root = () => {
  return (
    <div className={styles.root}>
      <LanguageSelect className={styles.sized} />
      <div className={styles.background}>
        <StaticImage
          placeholder="dominantColor"
          quality={100}
          src={'../../../../assets/images/enter/desktop-background.png'}
          alt=""
        />
      </div>
      <div className={styles.backgroundMobile}>
        <StaticImage
          quality={100}
          placeholder="dominantColor"
          src="../../../../assets/images/enter/mobile-background.png"
          alt=""
        />
      </div>
      <div className={styles.title}>
        {/* eslint-disable-next-line react/no-danger */}
        <h1 dangerouslySetInnerHTML={{ __html: getTranslate('main.title') }} />
      </div>

      <div className={styles.projects}>
        <Card
          icon={<WeterIcon />}
          title="W.E.T.E.R"
          description="Wind Energy Technology Era Renewable"
          to={'https://weter.tiaglin.com/{code}/'}
        />
        <Card
          animationDelay="0.75s"
          icon={<GorodIcon />}
          title="GOROD L.E.S"
          description="Logistic Eco System"
          to={'https://gorod.tiaglin.com/{code}/'}
        />
        <Card
          animationDelay="1.5s"
          icon={<VodaIcon />}
          title="V.O.D.A"
          description="Hydroelectric power plant of a new type"
          to={'https://voda.tiaglin.com/'}
        />
      </div>
      <div className={styles.projectsMobile}>
        <Project
          icon={<WeterIcon className={styles.icon} />}
          title="W.E.T.E.R"
          description="Wind Energy Schema Era Renewable"
          to={'https://weter.tiaglin.com/{code}/'}
        />
        <Project
          animationDelay="1.5s"
          icon={<GorodIcon className={styles.icon} />}
          title="GOROD L.E.S"
          description="Logistic Eco System"
          to={'https://gorod.tiaglin.com/{code}/'}
        />
        <Project
          animationDelay="3s"
          icon={<VodaIcon className={styles.icon} />}
          title="V.O.D.A"
          description="Hydroelectric power plant of a new type"
          missOnAlias
          to={'https://voda.tiaglin.com/'}
        />
      </div>
    </div>
  )
}
