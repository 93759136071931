/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Hexagon, HexagonImage, Link, LinkProps, Typography, Wave } from '@UIKit'

import * as styles from './Project.module.scss'
import { Translate } from 'react-localize-alias'

interface ProjectProps {
  icon: React.ReactNode
  title: string
  description: string
  to: LinkProps['to']
  missOnAlias?: boolean
  animationDelay?: string
}

export const Project: React.FC<ProjectProps> = ({ to, icon, title, description, missOnAlias, animationDelay }) => (
  <Link className={styles.root} to={to}>
    <HexagonImage className={styles.wrapper} withoutborder>
      <Wave fullHeight animationDelay={animationDelay}>
        <Hexagon className={styles.hexagon}>{icon}</Hexagon>
      </Wave>
    </HexagonImage>
    <div className={styles.text}>
      <h2>
        {title}
      </h2>

      {description}
    </div>
  </Link>
)
